<template>

	<div>

		<div class="container">
		    <ul class="projects-filter">
		    	<li><a href="#" :class="{ 'is-active' : category == null }" @click.prevent="filterByCategory(null)">All Projects</a></li>
				<li v-for="item in categories"><a href="#" :class="{ 'is-active' : item.id == category }" @click.prevent="filterByCategory(item.id)">{{ item.title }}</a></li>
		    </ul>
		</div>

	    <div class="container mb-50 mb-md-120 projects-grid-cls">
		    <div class="projects-grid" data-aos="fade-up">
		    
		        <div class="projects-grid__item" v-for="item in projects.slice(0, 6)"> 
		            <article class="project-item">
		                <a :href="item.url">
		                    <div class="project-item__img has-gradient" :style="'background-image:url(' + item.image + ')'"></div>
		                    <h3>{{ item.title }}</h3>
		                    <strong class="preheading">
								<span v-if="item.projectSectors.length">{{ item.projectSectors[0] }}, </span><span v-if="item.location">{{ item.location }}</span>
		                    </strong>   
		                </a>
		            </article>
		        </div>

		    </div><!--/projects-grid-->
		</div>

		<section class="container mb-50 mb-md-120 block-quote">
			<blockquote class="limit-width-md-930">
				<p>“{{ quote.quote }}”</p> 
				<footer>
            		{{ quote.quoteBy }}
        		</footer>
        	</blockquote>
        </section>


	    <div class="container mb-50 mb-md-120" v-if="projects.length > 6">
		    <div class="projects-grid" data-aos="fade-up">
		    
		        <div class="projects-grid__item" v-for="item in projects.slice(6, 999)"> 
		            <article class="project-item">
		                <a :href="item.url">
		                    <div class="project-item__img has-gradient" :style="'background-image:url(' + item.image + ')'"></div>
		                    <h3>{{ item.title }}</h3>
		                    <strong class="preheading">
								<span v-if="item.projectSectors.length">{{ item.projectSectors[0] }}, </span><span v-if="item.location">{{ item.location }}</span>
		                    </strong>   
		                </a>
		            </article>
		        </div>

		    </div><!--/projects-grid-->
		</div>

        <div class="text-center pb-120" v-if="morePagesAvailable">
		    <span class="btn btn--hollow-grey-light-border-h-white btn--with-plus" @click.prevent="getProjects">Load More</span>
		</div>

	</div>

</template>

<script>

    export default {
        data: function() {
            return { 
				category           : null,
				page               : 1,
				projects           : [],
				morePagesAvailable : false
            }
        },
        props : {
            categories : {
                default : null
            },
            quote : {
                default : null
            }
        },
        mounted() {
       		this.getProjects();
        },
        methods: {

            getProjects() {

                var payload = {
					category : this.category,
					page     : this.page
                };

                axios({
                    method : 'GET',
                    url    : '/projects.json',
                    params   : payload
                }).then(response => {

                	for (var i = response.data.data.length - 1; i >= 0; i--) {
                		this.projects.push(response.data.data[i]);
                	}

                	if (response.data.meta.pagination.current_page == response.data.meta.pagination.total_pages) {
                		this.morePagesAvailable = false;
                	}
                	else {
						this.morePagesAvailable = true;
						this.page               = response.data.meta.pagination.current_page + 1;
                	}

                }, response => {
                    console.log('Something went wrong');
                });

            },
            filterByCategory(id) {
            	this.projects = [];
				this.page     = 1;
				this.category = id;
				this.getProjects();
            }

        }
    }
    
</script>
