require('./bootstrap');

import Vue from 'vue/dist/vue.js';

// Cookies
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

// Swiper
import VueAwesomeSwiper from 'vue-awesome-swiper';
Vue.use(VueAwesomeSwiper);

// AOS - Animate On Scroll
import AOS from 'aos';
import 'aos/dist/aos.css';


// Moment
import moment from 'moment';
import VueMoment from 'vue-moment';
Vue.use(VueMoment, { moment });

Vue.component(
    'projects',
    require('./components/Projects.vue').default
);
Vue.component(
    'news',
    require('./components/News.vue').default
);

var app = new Vue({
    el: '#app',
    data: {
        contactFormIsActive : false,
        swiperCaption       : '',
        logoColour          : 'white',
        showAlert           : true,
    },
    methods: {
        toggleContactForm: function() {
            this.contactFormIsActive = !this.contactFormIsActive;
            // some code to filter users
        },
        hideAlert(md5String) {
            this.showAlert = md5String;
            this.$cookies.set("hideAlert", md5String, "14d");
        }
    },
    computed: {
        app() { 
            return this;
        }
    },
    delimiters: ['{:', ':}'],
    async mounted() {

        if (this.$cookies.get("hideAlert")) {
            this.showAlert = this.$cookies.get("hideAlert");
        }

        const AOS = await import('aos');
        AOS.init();
    },
});


// Main Navigation
const btn = document.querySelector(".hamburger-button");
const body = document.querySelector("body");
const mainNavigation = document.querySelector(".main-navigation");
const mainHeader = document.querySelector(".main-header");

btn.addEventListener("click", ()=>{
    btn.classList.toggle("is-active");
    mainHeader.classList.toggle("is-active");
    mainNavigation.classList.toggle("is-active");
    body.classList.toggle("ns");
});

// Go to Section (Hero)
var anchor = document.querySelector('#content');
var button = document.getElementsByClassName('js-scroll-to')[0];

if(button) {
    button.addEventListener('click', function() {
        anchor.scrollIntoView(true);
    });
}


// Collapsible Panels
document.querySelectorAll('.cp-heading').forEach(el => {
    el.addEventListener('click', e => {
        e.target.parentNode.classList.toggle('is-active');
    });
})


// Override the way field errors are displayed
document.addEventListener("freeform-render-field-errors", function (event) {
    // Stop the default field error rendering
    event.preventDefault();
  
    const form = event.form;
    const errors = event.errors;
  
    for (const fieldHandle in errors) {
      // Log each field errors to the console
      console.error("%s has the following errors: %s", fieldHandle, errors[fieldHandle].join('; '));
    }
  });

// Open New Window
const shareBtns = document.querySelectorAll('.js-share');
shareBtns.forEach(item => {
    item.addEventListener("click", function(e) {
        e.preventDefault();
        window.open(this.getAttribute("href"), null, 'width=800,height=600,location=0');
    });
})