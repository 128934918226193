<template>

	<div>
		<div class="container mb-60" v-if="news.length > 0">
        	<div class="news-grid">

				<div class="news-grid__item" v-for="item in news.slice(0, 999)"> 
					<article data-aos="fade-up" class="news-card">  
						<a :href="item.url">
							<figure v-if="item.image  && item.image.length > 0">
								<img :src="item.image" width="540" height="340" alt="" loading="lazy">
							</figure>
							<header>
								<strong class="preheading">{{ item.postDate | moment("Do MMMM YYYY") }}</strong>
								<h2>{{ item.title }}</h2>
							</header>
							<footer>
								<span class="read-more">Read More<span></span></span>
							</footer>
						</a>
					</article>
				</div>

			</div>
		</div>

		<div class="text-center pb-120" v-if="morePagesAvailable">
		    <span class="btn btn--hollow-grey-light-border-h-white btn--with-plus" @click.prevent="getNews">Load More</span>
		</div>
	</div>

</template>
<script>
    export default {
        data: function() {
            return { 
				page           : 1,
				news           : [],
				morePagesAvailable : false
            }
        },
        mounted() {
       		this.getNews();
        },
        methods: {

            getNews() {

                var payload = {
					page     : this.page
                };

                axios({
                    method : 'GET',
                    url    : '/news.json',
                    params   : payload
                }).then(response => {
                    // Iterate over the response data and push items directly into news array
                    response.data.data.forEach(item => {
                        this.news.push(item);
                    });

                    // Update morePagesAvailable and page values
                	if (response.data.meta.pagination.current_page == response.data.meta.pagination.total_pages) {
                		this.morePagesAvailable = false;
                	}
                	else {
						this.morePagesAvailable = true;
						this.page               = response.data.meta.pagination.current_page + 1;
                	}

                }).catch(error => {
                    console.log('Something went wrong');
                });

            }

        }
    }
</script>
